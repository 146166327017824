<template>
  <div :class="screenWidth > 800 ? 'home' : 'homeMin'">
    <!-- <div class="btn" v-if="screenWidth <= 800">
      <a-button
        type="primary"
        style="float: right; margin: 15px 15px 0 0; width: 100px"
        @click="showModal"
      >
        登录
      </a-button>
     
    </div> -->
    <div :class="screenWidth > 800 ? '' : 'home-leftMin'" class="home-left">
      <a-tabs
        :activeKey="activeKey"
        @change="callback"
        v-if="dataShow"
        class="tab-height"
      >
        <a-tab-pane key="5" tab="数字教材"> </a-tab-pane>
        <a-tab-pane key="4" tab="评审入口"> </a-tab-pane>
        <a-tab-pane key="6" tab="系统能力官网"> </a-tab-pane>
        
        <a-tab-pane key="3" tab="模拟赛">
          <div v-if="resData">
            <p
              class="left-font"
              :class="screenWidth > 800 ? '' : 'left-fontMin'"
            >
              {{ objListMN.name }}
            </p>
            <div :class="screenWidth > 800 ? 'left-pic' : 'left-picMin'">
              <div
                class="pic-flext"
                :class="screenWidth > 800 ? 'pic-flext' : 'pic-flextMin'"
              >
                <a-card
                  hoverable
                  class="card-list"
                  @click="
                    cardFun(
                      item.name,
                      1,
                      item.id,
                      index,
                      item.parent,
                      item.urltype,
                      item.targeturl
                    )
                  "
                  v-for="(item, index) in objListChangeMN"
                  :key="index"
                >
                  <img
                    slot="cover"
                    alt="example"
                    :style="item.bg"
                    :src="item.img"
                  />
                  <a-card-meta :title="item.name">
                    <template slot="description">
                      <!-- <p></p> -->
                      <p
                        :style="`font-size: 22px;font-weight: bold;margin-bottom: 0;text-align: center;color:${
                          item.detail != '即将开启' ? 'rgb(0, 147, 209)' : ''
                        }`"
                      >
                        {{ item.detail }}
                      </p>
                      <p style="text-align: center">{{ item.date }}</p>
                    </template>
                  </a-card-meta>
                </a-card>
                <a-card
                  hoverable
                  class="card-list"
                  v-if="
                    (String(objListChangeMN.length / 3).indexOf('.') > -1 &&
                      screenWidth > 1400) ||
                    (String(objListChangeMN.length / 2).indexOf('.') > -1 &&
                      screenWidth < 1400 &&
                      screenWidth > 800)
                  "
                  style="visibility: hidden"
                  @click="
                    cardFun(
                      item.name,
                      1,
                      item.id,
                      index,
                      item.parent,
                      item.urltype,
                      item.targeturl
                    )
                  "
                  :key="index"
                >
                  <img
                    slot="cover"
                    alt="example"
                    style="background: #d6efd6"
                    src="../images/banner-byi.png"
                  />
                  <a-card-meta title="占位">
                    <template slot="description">
                      <!-- <p></p> -->
                      <p>占位</p>
                    </template>
                  </a-card-meta>
                </a-card>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="1" :tab="objList.title">
          <p class="left-font" :class="screenWidth > 800 ? '' : 'left-fontMin'">
            {{ objList.name }}
            <span style="display: block; font-size: 18px">
              Computer System Development Capability Competition</span
            >
          </p>
          <div :class="screenWidth > 800 ? 'left-pic' : 'left-picMin'">
            <div
              class="pic-flext"
              :class="screenWidth > 800 ? 'pic-flext' : 'pic-flextMin'"
            >
              <!-- <a-card
                hoverable
                class="card-list"
                @click="cardTo('o')"
                :key="index"
              >
                <img slot="cover" alt="example" src="../images/WechatIMG5155.jpeg" />
                <a-card-meta
                  title="2023全国大学生计算机系统能力大赛-OceanBase 数据库大赛"
                >
                  <template slot="description">
              
                    <p
                      style="
                        font-size: 22px;
                        font-weight: bold;
                        margin-bottom: 0;
                        text-align: center;
                        color: rgb(0, 147, 209);
                      "
                    >
                      点击查看
                    </p>
                  </template>
                </a-card-meta>
              </a-card>
              <a-card
                hoverable
                class="card-list"
                @click="cardTo('龙')"
                :key="index"
              >
                <img slot="cover" alt="example" src="../images/ͷͼ.png" />
                <a-card-meta
                  title="2023全国大学生计算机系统能力大赛-龙芯杯（第七届）"
                >
                  <template slot="description">
                 
                    <p
                      style="
                        font-size: 22px;
                        font-weight: bold;
                        margin-bottom: 0;
                        text-align: center;
                        color: rgb(0, 147, 209);
                      "
                    >
                      点击查看
                    </p>
                  </template>
                </a-card-meta>
              </a-card> -->

              <a-card
                hoverable
                class="card-list"
                @click="
                  cardFun(
                    item.name,
                    1,
                    item.id,
                    index,
                    item.parent,
                    item.urltype,
                    item.targeturl
                  )
                "
                v-for="(item, index) in objListChange"
                :key="index"
              >
                <img
                  slot="cover"
                  alt="example"
                  :style="item.bg"
                  :src="item.img"
                />
                <a-card-meta :title="item.name">
                  <template slot="description">
                    <!-- <p></p> -->
                    <div v-if="item.targeturl == ''">
                      <p
                        :style="`font-size: 22px;font-weight: bold;margin-bottom: 0;text-align: center;color:${
                          item.detail != '即将开启' ? 'rgb(0, 147, 209)' : ''
                        }`"
                      >
                        {{ item.detail }}
                      </p>
                      <p style="text-align: center; margin-bottom: 0">
                        {{ item.date }}
                      </p>
               
                      <p
                        style="text-align: center"
                        v-if=" item.count!=''"
                      >
                        参赛队伍数量：{{ item.count }}
                      </p>
                    </div>
                    <div v-else>
                      <p
                        style="
                          font-size: 22px;
                          font-weight: bold;
                          margin-bottom: 0;
                          text-align: center;
                          color: rgb(0, 147, 209);
                        "
                      >
                        点击查看
                      </p>
                    </div>
                  </template>
                </a-card-meta>
              </a-card>
              <a-card
                hoverable
                class="card-list"
                v-if="
                  (String(objListChange.length / 3).indexOf('.') > -1 &&
                    screenWidth > 1400) ||
                  (String(objListChange.length / 2).indexOf('.') > -1 &&
                    screenWidth < 1400 &&
                    screenWidth > 800)
                "
                style="visibility: hidden"
                @click="
                  cardFun(
                    item.name,
                    1,
                    item.id,
                    index,
                    item.parent,
                    item.urltype,
                    item.targeturl
                  )
                "
                :key="index"
              >
                <img
                  slot="cover"
                  alt="example"
                  style="background: #d6efd6"
                  src="../images/banner-byi.png"
                />
                <a-card-meta title="占位">
                  <template slot="description">
                    <!-- <p></p> -->
                    <p>占位</p>
                  </template>
                </a-card-meta>
              </a-card>

              <!-- <a-card hoverable class="card-list">
                <img
                  slot="cover"
                  alt="example"
                  style="background: #d6efd6"
                  src="../images/WechatIMG27053.png"
                />

                <a-card-meta title="er">
                  <template slot="description">
           
                    <p></p>
                  </template>
                </a-card-meta>
              </a-card> -->
              <a-card
                hoverable
                class="card-list"
                style="width: 100%; background: rgb(0, 0, 0)"
              >
                <video
                  controls="controls"
                  width="60%"
                  x5-playsinline
                  webkit-playsinline="true"
                  playsinline="true"
                  muted="muted"
                  style="cursor: pointer; margin: 0 auto; display: inherit"
                >
                  <source src="../images/homeMp4.mp4" type="video/mp4" />
                </video>
              </a-card>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="往期比赛" force-render>
          <div v-for="(item, index) in arryList" :key="index" class="card-p">
            <p
              class="right-font left-font font-mar"
              :class="screenWidth > 800 ? '' : 'left-fontMin'"
            >
              {{ item.name }}
            </p>
            <div class="card-flex">
              <a-card
                @click="to('./oldDetail', i.name, i.parent)"
                size="small"
                v-for="(i, ii) in item.list"
                :key="ii"
                :title="i.sname"
                :class="screenWidth > 800 ? 'cardWidth' : 'cardWidthMin'"
                hoverable
              >
                <a slot="extra" href="javascript:;"> 查看详情 </a>
                <p class="break">{{ i.detail }}</p>
              </a-card>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
      <div class="footer-style">
        <div>
          <P v-if="urlM == 'is'">
            <span class="footer-title">主办单位：</span
            ><span
              ><a href="http://www.creacu.org.cn/#/home" target="_blank">
                全国高等学校计算机教育研究会
              </a>
              | 系统能力培养研究专家组 | 武汉大学</span
            >
          </P>

          <P v-if="urlC == 'compiler' || urlB == 'db' || urlO == 'oceanbase'">
            <span class="footer-title">主办单位：</span
            ><span
              ><a href="http://www.creacu.org.cn/#/home" target="_blank">
                全国高等学校计算机教育研究会
              </a>
              | 系统能力培养研究专家组
              | 系统能力培养研究项目发起高校</span
            >
          </P>
          <P v-else>
            <span class="footer-title">主办单位：</span
            ><span
              ><a href="http://www.creacu.org.cn/#/home" target="_blank">
                全国高等学校计算机教育研究会
              </a>
              | 系统能力培养研究专家组 | 系统能力培养研究项目发起高校</span
            >
          </P>

          <p v-if="urlO == 'oceanbase'">
            <span class="footer-title">承办单位：</span><span> OceanBase </span>
          
          </p>
          <p v-else-if="urlS == 'pra'">
            <span class="footer-title">承办单位：</span><span> 曙光信息产业股份有限公司  |   天津大学
 </span>
          </p>
          
          <p v-else-if="urlM == 'is'">
            <span class="footer-title">承办单位：</span><span> 武汉大学 </span>
          </p>
          <p v-else-if="urlL == 'os' || urlC == 'compiler'">
            <span class="footer-title">承办单位：</span
            ><span> 杭州电子科技大学 </span>
          </p>
          <p v-else-if="urlB == 'db'">
            <!-- <span class="footer-title">承办单位：</span
            ><span> 杭州电子科技大学 </span> -->
          </p>

          <p v-else>
            <span class="footer-title">承办单位：</span
            ><span
              ><a href="https://www.hdu.edu.cn/main.htm" target="_blank">
                杭州电子科技大学
              </a></span
            >
          </p>
          <!-- <p>
            <span>企业承办单位：</span
            ><span
              ><a href="https://www.oceanbase.com/" target="_blank">
                北京奥星贝斯科技有限公司
              </a><img src="../images/BAILOGO.png" width="120px"/></span
            >
          </p> -->
          <!--oceanbase.educg.net-->
          <p v-if="urlO == 'oceanbase'">
            <span class="footer-title">协办单位：</span
            ><span
              >
              蚂蚁技术研究院
              |
              机械工业出版社
              | 希冀平台 | 墨天轮

              
                    
            </span>
          </p>
          <p v-else-if="urlM == 'is'">
            <span class="footer-title">协办单位：</span
            ><span> 小米科技有限责任公司 | 机械工业出版社 </span>
          </p>
          <p v-else-if="urlS == 'pra'">
            <span class="footer-title">协办单位：</span><span> 机械工业出版社  |   希冀平台 </span>
          </p>
          <p v-else-if="urlL == 'os'">
            <span class="footer-title">协办单位：</span
            ><span>中国计算机学会系统软件专委会 | 机械工业出版社 </span>
          </p>
          <p v-else-if="urlC == 'compiler'">
            <span class="footer-title">协办单位：</span
            ><span
              >华为技术有限公司 | 机械工业出版社 | 教育部编译课程虚拟教研室
            </span>
          </p>
          <p v-else-if="urlB == 'db'">
            <span class="footer-title">协办单位：</span
            ><span
              >
              教育部“101 计划”数据库系统课程虚拟教研室 | 教育部-华为“智能基座”数据库课程虚拟教研室 （中国人民大学） |  机械工业出版社  | 希冀平台
            </span>
          </p>

          <p v-else>
            <span class="footer-title">协办单位：</span
            ><span
              ><a href="http://tcss.ccf.org.cn/" target="_blank">
                中国计算机学会系统软件专委会
              </a>
              |

              <a href="http://www.hzbook.com/" target="_blank">
                机械工业出版社
              </a>
            </span>
          </p>
          <p v-if="urlO == '' && urlC!='compiler' && urlB != 'db'&& urlS!= 'pra'">
            <span class="footer-title">技术平台：</span
            ><span>
              <a href="https://www.educg.net/" target="_blank">
                希冀信息类专业教学实践一体化平台
              </a>
              <span v-if="urlM != 'is'"> | 军事科学院</span>
            </span>
          </p>

          <p v-if="urlC == 'compiler' || urlB == 'db' || urlS == 'pra'">
            <span class="footer-title">技术平台：</span
            ><span>
              希冀平台
            </span>
          </p>
       
          
          <p v-if="urlM == 'is' || urlL == 'os'">
            <span class="footer-title"> 大赛官网：</span
            ><span>
              郑州云海科技有限公司 | 阔思格睿网络科技（北京）有限责任公司
            </span>
          </p>


          <p>
            <span style="display: block">
              <a href="https://beian.miit.gov.cn/" target="_blank">
                豫ICP备18015569号
              </a>
              <span style="margin: 0 10px">|</span>
              <a href="https://beian.miit.gov.cn/" target="_blank">
                京ICP备17065346号
              </a>
              <span style="margin: 0 10px">|</span>
              <img
                src="../images/gongan.png"
                style="vertical-align: text-bottom"
              /><span
                ><a
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"
                  target="_blank"
                >
                  豫公网安备 41010702002654号
                </a>
              </span>
            </span>
          </p>
        </div>
        <!-- <div>
          <p>
            <span style="display: block; font-size: 20px; font-weight: bold"
              >主办单位</span
            >
            <span style="display: block"
              ><a href="http://www.kylinos.cn/" target="_blank">
                全国高等学校计算机教育研究会
              </a></span
            >
            <span style="display: block">系统能力培养研究专家组</span>
            <span style="display: block">系统能力培养研究项目发起高校</span>
          </p>
        </div>
        <div>
          <p>
            <span class="footer-title"
              >协办单位</span
            >
            <span style="display: block"
              ><a href="http://tcss.ccf.org.cn/" target="_blank">
                中国计算机学会系统软件专委会
              </a></span
            >
            <span style="display: block"> <a href="http://www.ict.ac.cn/" target="_blank">
                中国科学院计算技术研究所
              </a></span>
            <span style="display: block"><a href="http://www.hzbook.com/" target="_blank">
                机械工业出版社华章分社
              </a></span>
          </p>
        </div>
        <div>
          <p>
            <span class="footer-title"
              >技术平台</span
            >
            <span style="display: block"
              ><a href="https://www.educg.net.cn/" target="_blank">
                希冀计算机课程一体化支撑平台
              </a></span
            >
          
          </p>
        </div> -->
        <div>
          <p style="text-align: center">
            <span
              class="footer-title"
              style="display: block; margin-bottom: 10px"
              >联 系 我 们</span
            >
            <img src="../assets/WechatIMG29605.png" width="100px" />
            <!-- <span style="display: block">周一至周五9:00-18:00在线</span> -->
          </p>
        </div>
        <div>
          <p style="text-align: center">
            <span
              class="footer-title"
              style="display: block; margin-bottom: 10px"
              >系统能力培养的公众号</span
            >
            <img src="../assets/WechatIMG29616.png" width="100px" />
          </p>
        </div>
      </div>
    </div>
    <div class="home-right" :class="screenWidth > 800 ? '' : 'home-rightMin'">
      <div style="height: 10vh">
        <div style="margin-bottom: 20px">
          <img src="../images/logo.png" width="50%" />
        </div>
        <p>{{ loginDetail }}</p>
        <a-button type="primary" @click="login" v-if="!loginOk">
          登录
        </a-button>
        <p
          v-if="loginOk"
          style="
            font-size: 16px;
            font-weight: bold;
            display: inline-block;
            color: #5b5b5b;
          "
        >
          欢迎回来！{{ user }}
        </p>
      </div>
      <!--新闻-->
      <div
        style="
          border-top: 1px dashed rgb(204, 204, 204);
          margin-top: 20px;
          overflow: hidden;
        "
      >
        <p class="right-title" style="padding-top: 15px">通知、新闻</p>
        <newsvue :noticeList="news" :numAdd="numAdd"></newsvue>
      </div>
      <!--赞助商-->
      <div
        style=""
        :style="
          screenWidth > 1100
            ? 'border-top: 1px dashed #ccc; padding-top: 20px; position:absolute;bottom:10px;right:0;left:0;padding:20px 20px 0;    font-size: 12px;'
            : 'border-top: 1px dashed #ccc; padding-top: 20px;bottom: 10px;     font-size: 12px;'
        "
      >
        <!-- <p class="right-title" ><a href="javascript:;" @click="dw('./dw')">赞助单位（2022年）</a> <a href="javascript:;" @click="dw('./qy')" style="float:right"> 招聘绿色通道</a></p> -->

        <p class="right-title">
          操作系统赛赞助名单<a
            href="javascript:;"
            @click="dw('./qy')"
            style="float: right"
          >
            招聘绿色通道</a
          >
        </p>

        <ul
          style="border-bottom: 1px dashed #ccc; padding-bottom: 10px"
          class="footer-all footer-all-list"
        >
          <li class="o">
            <span style="float: right"
              >
              <svg
                t="1692328945276"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3607"
                width="16"
                height="16"
              >
                <path
                  d="M89.315556 394.808889l184.888888-187.164445a28.444444 28.444444 0 0 1 20.195556-8.533333h435.768889a28.444444 28.444444 0 0 1 20.195555 8.533333l184.888889 187.164445a28.444444 28.444444 0 0 1 1.564445 38.257778l-413.866667 492.657777a14.222222 14.222222 0 0 1-20.053333 1.706667l-1.848889-1.706667L87.751111 433.066667a28.444444 28.444444 0 0 1 1.564445-38.257778z"
                  fill="#FFD491"
                  p-id="3608"
                ></path>
                <path
                  d="M512 199.111111L312.888889 412.444444h398.222222L512 199.111111z"
                  fill="#FFE7C1"
                  p-id="3609"
                ></path>
                <path
                  d="M312.888889 412.444444l199.111111 554.666667 199.111111-554.666667H312.888889z"
                  fill="#FFDBA3"
                  p-id="3610"
                ></path>
                <path
                  d="M711.111111 412.444444h265.386667L512 967.111111l199.111111-554.666667z"
                  fill="#FFA768"
                  p-id="3611"
                ></path>
                <path
                  d="M312.888889 412.444444H56.888889l455.111111 554.666667-199.111111-554.666667z"
                  fill="#FFA768"
                  p-id="3612"
                >
              </path>
                </svg
            >
          </span>
            <span style=""
              ><a href="https://www.huawei.com/" target="_blank">
                <img src="../images/huawei.png" width="20px;" />
                华为
              </a>
              <span style="margin: 5px; display: inline-block">|</span>
              <a href="http://www.kylinos.cn/" target="_blank">
                <img src="../images/qilin.png" width="25px;" />
                麒麟软件
              </a>
              <!-- <span style="margin: 0 10px">|</span>
              <a href="https://www.uniontech.com/" target="_blank">
                <img src="../images/tongxin.png" width="40px;" />
                统信软件
              </a> -->
            </span>
          </li>
          <li class="t">
            <span style="float: right">
              <svg
                t="1709794150262"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="5129"
                width="16"
                height="16"
              >
                <path
                  d="M287.762 124.318v252.871c0 123.331 100.905 224.238 224.238 224.238 123.331 0 224.238-100.907 224.238-224.238V124.318H287.762z"
                  fill="#D81919"
                  p-id="5130"
                ></path>
                <path
                  d="M379.487 124.318v149.434c0 72.882 59.63 132.513 132.513 132.513 72.882 0 132.513-59.631 132.513-132.513V124.318H379.487z"
                  fill="#FACC60"
                  p-id="5131"
                ></path>
                <path
                  d="M644.513 124.318v149.434c0 72.882-59.631 132.514-132.513 132.514-72.884 0-132.514-59.631-132.514-132.514V124.318h265.027m19.201-19.201H360.285v168.635c0 83.656 68.059 151.714 151.715 151.714s151.714-68.059 151.714-151.714V105.117z"
                  fill="#FFFFFF"
                  p-id="5132"
                ></path>
                <path
                  d="M512 622.736m-339.055 0a339.055 339.055 0 1 0 678.11 0 339.055 339.055 0 1 0-678.11 0Z"
                  fill="#F8B643"
                  p-id="5133"
                ></path>
                <path
                  d="M512 622.736m-250.074 0a250.074 250.074 0 1 0 500.148 0 250.074 250.074 0 1 0-500.148 0Z"
                  fill="#FFF89F"
                  p-id="5134"
                ></path>
                <path
                  d="M512 372.662c138.112 0 250.074 111.962 250.074 250.074S650.112 872.81 512 872.81c-138.113 0-250.074-111.962-250.074-250.074S373.888 372.662 512 372.662m0-24.001c-151.126 0-274.075 122.95-274.075 274.075S360.874 896.811 512 896.811s274.075-122.95 274.075-274.076S663.126 348.661 512 348.661z"
                  fill="#FFFFFF"
                  p-id="5135"
                ></path>
                <path
                  d="M786.015 124.319h-548.03c-17.08 0-31.055-13.975-31.055-31.055s13.975-31.055 31.055-31.055h548.029c17.08 0 31.055 13.975 31.055 31.055 0.001 17.08-13.974 31.055-31.054 31.055z"
                  fill="#231815"
                  p-id="5136"
                ></path>
                <path
                  d="M556.594 800.727h-51.41V527.763l-68.956 39.578 0.408-51.41 82.827-48.147h37.131v332.943z"
                  p-id="5137"
                ></path>
              </svg>
            </span>
            <span style="">
              <a href="http://www.loongson.cn/" target="_blank" style="">
                <img src="../images/longxin01.png" width="14px;" />
                龙芯中科
              </a>
              <span style="margin: 5px; display: inline-block">|</span>
              <a
                href="https://www.antgroup.com/"
                target="_blank"
                style="display: inline-block"
              >
                <img src="../images/mayi01.png" width="16px;" />
                蚂蚁集团
              </a>
              <span style="margin: 5px; display: inline-block">|</span>

              <a
                href="https://www.oppo.com/cn/smartphones/"
                target="_blank"
                style=""
              >
                <!-- <img src="../images/longxin01.png" width="14px;" /> -->
                OPPO
              </a>
              |
              <a href="https://www.vivo.com/" target="_blank" style="">
                <!-- <img src="../images/longxin01.png" width="14px;" /> -->
                vivo</a
              >

              <span style="margin: 0 5px">|</span>
              <span style="display: flex">
                <a href="https://www.mi.com/" target="_blank">
                  <!-- <img src="../images/wuian01.png" width="14px;" /> -->
                  <svg
                    data-v-777bda80=""
                    style="width: 14px; vertical-align: middle"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 808 808"
                    class="mi-logo"
                  >
                    <g data-v-777bda80="">
                      <path
                        data-v-777bda80=""
                        fill="#ff6900"
                        d="M723.79,84.42C647.55,8.48,537.94,0,404,0,269.89,0,160.12,8.58,83.92,84.72S0,270.43,0,404.39,7.74,648,84,724.14,269.9,808,404,808s243.85-7.71,320-83.86,84-185.78,84-319.75C808,270.25,800.16,160.54,723.79,84.42Z"
                      ></path>
                      <path
                        data-v-777bda80=""
                        fill="#fff"
                        d="M374.26,553.72a5,5,0,0,1-5.06,5H300.3a5.05,5.05,0,0,1-5.12-5V373.53a5.05,5.05,0,0,1,5.12-5h68.9a5,5,0,0,1,5.06,5Z"
                      ></path>
                      <path
                        data-v-777bda80=""
                        fill="#fff"
                        d="M509.18,553.72a5.05,5.05,0,0,1-5.09,5H438.5a5,5,0,0,1-5.1-5V398.26c-.07-27.15-1.62-55-15.64-69.06-12-12.09-34.51-14.86-57.88-15.44H241a5,5,0,0,0-5.07,5v235a5.07,5.07,0,0,1-5.12,5H165.16a5,5,0,0,1-5.06-5V254.31a5,5,0,0,1,5.06-5H354.52c49.49,0,101.22,2.26,126.74,27.81s27.92,77.3,27.92,126.85Z"
                      ></path>
                      <path
                        data-v-777bda80=""
                        fill="#fff"
                        d="M644.29,553.72a5.06,5.06,0,0,1-5.09,5H573.57a5,5,0,0,1-5.08-5V254.31a5,5,0,0,1,5.08-5H639.2a5.06,5.06,0,0,1,5.09,5Z"
                      ></path>
                    </g>
                  </svg>
                  小米
                </a>
              </span>
            </span>
          </li>
          <li class="t">
            <span style="float: right">
              <svg
                t="1709794199507"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="6387"
                width="16"
                height="16"
              >
                <path
                  d="M287.762 124.318v252.871c0 123.331 100.905 224.238 224.238 224.238 123.331 0 224.238-100.907 224.238-224.238V124.318H287.762z"
                  fill="#82A0AF"
                  p-id="6388"
                ></path>
                <path
                  d="M379.487 124.318v149.434c0 72.882 59.63 132.513 132.513 132.513 72.882 0 132.513-59.631 132.513-132.513V124.318H379.487z"
                  fill="#E0EAED"
                  p-id="6389"
                ></path>
                <path
                  d="M644.513 124.318v149.434c0 72.882-59.631 132.514-132.513 132.514-72.884 0-132.514-59.631-132.514-132.514V124.318h265.027m19.201-19.201H360.285v168.635c0 83.656 68.059 151.714 151.715 151.714s151.714-68.059 151.714-151.714V105.117z"
                  fill="#FFFFFF"
                  p-id="6390"
                ></path>
                <path
                  d="M512 622.736m-339.055 0a339.055 339.055 0 1 0 678.11 0 339.055 339.055 0 1 0-678.11 0Z"
                  fill="#9EC2F7"
                  p-id="6391"
                ></path>
                <path
                  d="M512 622.736m-250.074 0a250.074 250.074 0 1 0 500.148 0 250.074 250.074 0 1 0-500.148 0Z"
                  fill="#DDECF7"
                  p-id="6392"
                ></path>
                <path
                  d="M512 372.662c138.112 0 250.074 111.962 250.074 250.074S650.112 872.81 512 872.81c-138.113 0-250.074-111.962-250.074-250.074S373.888 372.662 512 372.662m0-24.001c-151.126 0-274.075 122.95-274.075 274.075S360.874 896.811 512 896.811s274.075-122.95 274.075-274.076S663.126 348.661 512 348.661z"
                  fill="#FFFFFF"
                  p-id="6393"
                ></path>
                <path
                  d="M786.015 124.319h-548.03c-17.08 0-31.055-13.975-31.055-31.055s13.975-31.055 31.055-31.055h548.029c17.08 0 31.055 13.975 31.055 31.055 0.001 17.08-13.974 31.055-31.054 31.055z"
                  fill="#231815"
                  p-id="6394"
                ></path>
                <path
                  d="M623.478 748.689v42.438h-218.7v-34.684l128.12-130.562c13.599-13.599 23.256-25.839 28.97-36.723 5.71-10.875 8.569-22.576 8.569-35.092 0-17.949-5.241-32.233-15.708-42.836-10.477-10.613-24.685-15.915-42.639-15.915-16.594 0-30.465 4.285-41.617 12.849-11.157 8.569-19.177 20.748-24.071 36.517l-37.947-22.435c7.346-22.576 19.993-39.785 37.947-51.616 17.949-11.832 40.394-17.752 67.32-17.752 20.945 0 39.644 4.285 56.102 12.854 16.454 8.569 29.237 20.542 38.355 35.908 9.108 15.371 13.669 32.711 13.669 52.024 0 18.498-4.631 35.43-13.876 50.796-9.249 15.371-24.343 33.803-45.288 55.287l-86.09 88.944h146.884z"
                  p-id="6395"
                ></path>
              </svg>
            </span>
            <span style="">
              <a href="https://openanolis.cn/" target="_blank">
                <img
                  src="../images/longxi01.png"
                  width="16px;"
                  style="margin-right: 5px"
                />龙蜥社区
              </a>
            </span>
          </li>
          <li class="t">
            <span style="float: right">
              <svg
                t="1709794239286"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="7619"
                width="16"
                height="16"
              >
                <path
                  d="M287.762 124.318v252.871c0 123.331 100.905 224.238 224.238 224.238 123.331 0 224.238-100.907 224.238-224.238V124.318H287.762z"
                  fill="#BF4C19"
                  p-id="7620"
                ></path>
                <path
                  d="M379.487 124.318v149.434c0 72.882 59.63 132.513 132.513 132.513 72.882 0 132.513-59.631 132.513-132.513V124.318H379.487z"
                  fill="#DC8C57"
                  p-id="7621"
                ></path>
                <path
                  d="M644.513 124.318v149.434c0 72.882-59.631 132.514-132.513 132.514-72.884 0-132.514-59.631-132.514-132.514V124.318h265.027m19.201-19.201H360.285v168.635c0 83.656 68.059 151.714 151.715 151.714s151.714-68.059 151.714-151.714V105.117z"
                  fill="#FFFFFF"
                  p-id="7622"
                ></path>
                <path
                  d="M512 622.736m-339.055 0a339.055 339.055 0 1 0 678.11 0 339.055 339.055 0 1 0-678.11 0Z"
                  fill="#FCC181"
                  p-id="7623"
                ></path>
                <path
                  d="M512 622.736m-250.074 0a250.074 250.074 0 1 0 500.148 0 250.074 250.074 0 1 0-500.148 0Z"
                  fill="#FFE6BF"
                  p-id="7624"
                ></path>
                <path
                  d="M512 372.662c138.112 0 250.074 111.962 250.074 250.074S650.112 872.81 512 872.81c-138.113 0-250.074-111.962-250.074-250.074S373.888 372.662 512 372.662m0-24.001c-151.126 0-274.075 122.95-274.075 274.075S360.874 896.811 512 896.811s274.075-122.95 274.075-274.076S663.126 348.661 512 348.661z"
                  fill="#FFFFFF"
                  p-id="7625"
                ></path>
                <path
                  d="M786.015 124.319h-548.03c-17.08 0-31.055-13.975-31.055-31.055s13.975-31.055 31.055-31.055h548.029c17.08 0 31.055 13.975 31.055 31.055 0.001 17.08-13.974 31.055-31.054 31.055z"
                  fill="#231815"
                  p-id="7626"
                ></path>
                <path
                  d="M627.439 699.539c0 20.677-4.692 39.105-14.077 55.287-9.385 16.187-22.51 28.764-39.372 37.741-16.871 8.977-36.18 13.468-57.94 13.468-25.843 0-48.082-5.916-66.711-17.752-18.634-11.832-31.351-28.079-38.148-48.757l37.947-22.44c5.161 14.687 13.463 26.11 24.887 34.272 11.424 8.161 25.431 12.24 42.025 12.24 18.493 0 33.386-5.916 44.678-17.748 11.283-11.832 16.932-27.264 16.932-46.31 0-19.585-5.649-34.947-16.932-46.104-11.293-11.152-26.725-16.73-46.31-16.73-19.857 0-34.68 6.394-44.472 19.177l-28.97-31.417 108.53-113.836H424.654v-42.846h187.691v34.684l-86.91 91.804c19.585 0.272 37.131 4.894 52.633 13.871 15.507 8.977 27.606 21.423 36.316 37.333 8.7 15.914 13.055 33.938 13.055 54.063z"
                  p-id="7627"
                ></path>
              </svg>
            </span>
            <span style="">
              <a href="https://ucas.com.cn/" target="_blank">
                <!-- <img src="../images/longxi01.png" width="16px;"  style="margin-right:5px;"/> -->
                国科环宇
              </a>
              <span style="margin: 0 10px">|</span>

              <a href="https://www.phytium.com.cn/" target="_blank" style="">
                飞腾
              </a>
            </span>
          </li>

          <li class="f">
            <span style="display: block; font-weight: 500">硬件支持</span>
            <span style="float: left">
              <a href="https://www.phytium.com.cn/" target="_blank"> 飞腾 </a>

              <span style="margin: 5px; display: inline-block">|</span>
              黑芝麻
              <span style="margin: 5px; display: inline-block">|</span>
              <a href="https://www.canaan-creative.com/" target="_blank">
                <img src="../images/jianan.png" width="16px;" />
                嘉楠科技
              </a>
              <span style="margin: 5px; display: inline-block">|</span>
              <a href="http://www.loongson.cn/" target="_blank" style="">
                <img src="../images/longxin01.png" width="14px;" />
                龙芯中科
              </a>
              <span style="margin: 5px; display: inline-block">|</span>
              <a href="https://www.t-head.cn/" target="_blank">
                <img src="../images/pingtou002.webp" width="20px;" />
                平头哥半导体
              </a>
              <span style="margin: 5px; display: inline-block">|</span>
              <a href="https://www.starfivetech.com/" target="_blank">
                <img src="../images/jianan02.png" width="15px;" />
                赛昉科技
              </a>
              <span style="margin: 5px; display: inline-block">|</span>
              <img src="../images/suanneng.png" width="30px;" />
              北京算能

              <span style="margin: 5px; display: inline-block">|</span>
              中科院软件所
            </span>
          </li>
        </ul>
        <p class="right-title">编译系统设计赛赞助商名单</p>
        <ul
          style="border-bottom: 1px dashed #ccc; padding-bottom: 10px"
          class="footer-all"
        >
          <li>
            <span style="float: left"
              ><a href="https://www.huawei.com/" target="_blank">
                <img src="../images/huawei.png" width="20px;" />
                华为
              </a>
            </span>
          </li>
        </ul>
        <p class="right-title">数据库管理系统设计赛赞助商名单</p>
        <ul
          style="border-bottom: 1px dashed #ccc; padding-bottom: 10px"
          class="footer-all"
        >
          <li>
            <span style="float: left"
              ><a href="https://www.kingbase.com.cn/" target="_blank">
                <img src="../images/kingbase.png" width="40px;" />
                人大金仓
              </a>
              <span style="margin: 5px; display: inline-block">|</span>
              <a
                href="https://market.cloud.tencent.com/stores/1255389563"
                target="_blank"
              >
                <img src="../images/tengxunyun01.png" width="16px;" />
                腾讯云
              </a>
              <!-- <span style="margin: 5px; display: inline-block">|</span>
              <a href="https://www.oceanbase.com/" target="_blank">
                <img src="../images/WechatIMG5611.png" width="60px;" />
                OceanBase
              </a> -->
            </span>
          </li>
        </ul>
        <ul
          style="border-bottom: 1px dashed #ccc; padding-bottom: 10px"
          class="footer-all"
        >
          <li>
            <p class="right-title">媒体支持</p>
            <span style="float: left"
              ><a href="https://www.csdn.net/" target="_blank">
                <img src="../images/csdn01.png" width="14px;" />
                CSDN
              </a>
            </span>
          </li>
        </ul>
        <!-- <p>
          <span class="footer-title">技术支持：</span
          ><span>
            <a href="https://www.canaan-creative.com/" target="_blank">
              嘉楠科技
            </a>
          </span>
        </p> -->
        <!-- <p>
          <span class="footer-title">媒体支持：</span
          ><span
            ><a href="https://www.csdn.net/" target="_blank"> CSDN </a> |
            <a href="https://www.geekbang.org/" target="_blank"> Geekbang </a>
            | <a href="https://www.infoq.cn/" target="_blank"> InfoQ </a></span
          >
        </p> -->
      </div>
    </div>

    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <reg
      :visible="visible"
      :visibleIphon="visibleIphon"
      :typePran="1"
      @regFun="regFun(arguments)"
      v-if="visible == true"
    >
    </reg> -->
  </div>
</template>
<script>
// @ is an alias to /src
// import reg from "@/components/reg.vue";
//EduCGRunning  //服务运行状态检测点；
import newsvue from "@/components/newsvue.vue";

export default {
  name: "Home",
  components: {
    newsvue,
  },
  data() {
    return {
      screenWidth: null,
      dataShow: false,
      visible: false,
      visibleIphon: false,
      loginOk: false,
      user: "",
      objList: {},
      objListMN: {},
      objListChange: [],
      objListChangeMN: [],
      loginDetail: "",
      arryList: [],
      news: [],
      index: "",
      resData: false,
      numAdd: 2,
      type: "db",
      activeKey: "1",
      urlO: "",
      urlM: "",
      urlL: "",
      urlC: "",
      urlB:'',
      urlS:'',
      shoeW: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.shoeW = true;
    }, 100);
    this.urlO =
      window.location.href.indexOf("oceanbase.educg.net") != -1
        ? "oceanbase"
        : "";
    this.urlM = window.location.href.indexOf("is.educg.net") != -1 ? "is" : "";
    this.urlL = window.location.href.indexOf("os.educg.net") != -1 ? "os" : "";

    this.urlC =
      window.location.href.indexOf("compiler.educg.net") != -1
        ? "compiler"
        : "";
        this.urlB =
      window.location.href.indexOf("db.educg.net") != -1
        ? "db"
        : "";
        
        this.urlS =
      window.location.href.indexOf("pra.educg.net") != -1
        ? "pra"
        : "";

    // if(window.location.href.indexOf('os.educg.net ')!=-1){//操作系统的卡片放最前面
    //   this.type = 'os'

    // }
    // else {
    //   this.type = 'db'
    // }
    window.localStorage.setItem("anjing-joincode", "");
    this.init();
    this.initMn();
    this.screenWidth = document.body.clientWidth;
    if (window.localStorage.getItem("anjing-tokenName")) {
      this.user = window.localStorage.getItem("anjing-tokenName");
      this.loginOk = true;
    }
    if (document.body.clientHeight < 870) {
      this.numAdd = 2;
    } else if (
      document.body.clientHeight > 870 &&
      document.body.clientHeight < 1000
    ) {
      this.numAdd = 4;
    } else {
      this.numAdd = 6;
    }

    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        if (document.body.clientHeight < 870) {
          this.numAdd = 2;
        } else if (
          document.body.clientHeight > 870 &&
          document.body.clientHeight < 1000
        ) {
          this.numAdd = 4;
        } else {
          this.numAdd = 6;
        }
      })();
    };
  },

  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val <= 900) {
          console.log("屏幕宽度小于800px" + oldVal);
        } else {
          console.log("屏幕宽度大于800px");
        }
      },

      immediate: true,
    },
    shoeW() {
      this.urlO =
        window.location.href.indexOf("oceanbase.educg.net") != -1
          ? "oceanbase"
          : "";
      this.urlM =
        window.location.href.indexOf("is.educg.net") != -1 ? "is" : "";
      this.urlL =
        window.location.href.indexOf("os.educg.net") != -1 ? "os" : "";
      this.urlC =
        window.location.href.indexOf("compiler.educg.net") != -1
          ? "compiler"
          : "";
          this.urlB =
      window.location.href.indexOf("db.educg.net") != -1
        ? "db"
        : "";
    },
  },

  methods: {
    cardTo(v) {
      if (v == "龙") {
        window.open("http://www.nscscc.com/");
      }
      if (v == "o") {
        window.open("https://open.oceanbase.com/competition");
      }
    },
    login() {
      this.$router.push({ path: "./login-reg-pass", query: { url: "./home" } });
    },
    to(url, name, parent) {
      this.$router.push({ path: url, query: { name: name } });
      window.localStorage.setItem("anjing-parent", parent);
    },
    dw(url) {
      this.$router.push({ path: url });
    },
    init() {
      let data = {
        op: "matchlist",
        // type:this.type
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.dataShow = true;
            _this.objList = response.data.data.current;
            let tempAry = response.data.data.current.list;
            _this.news = response.data.data.news;
            console.log(_this.news);

            _this.objListChange = tempAry; // tempAry.slice(1,tempAry.length)
            console.log(response.data.data.current.list);
            console.log(_this.objListChange);

            _this.arryList = response.data.data.old;
            _this.loginDetail = response.data.data.login;
          } else {
            console.log(0);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    initMn() {
      let data = {
        op: "simlist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.dataShow = true;
            _this.objListMN = response.data.data.current;
            let tempAry = response.data.data.current.list;
            // _this.news= response.data.data.news;
            console.log(_this.news);

            _this.objListChangeMN = tempAry; // tempAry.slice(1,tempAry.length)
            // console.log(response.data.data.current.list);
            // console.log(_this.objListChange);

            // _this.arryList = response.data.data.old;
            // _this.loginDetail = response.data.data.login;
          } else {
            console.log(0);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showModal() {
      this.visibleIphon = true;
    },
    showDrawer() {
      this.visible = true;
    },
    callback(key) {
      this.activeKey = key;
      console.log(key);
      if (key == 3) {
        this.resData = true;
      } else if (key == 4) {
        window.open(this.url + "/specialist/index.html");
        this.activeKey = "1";
      } else if (key == 5) {
        // window.location.href =
        window.open("https://www.xuezhiqiao.com/");
        this.activeKey = "1";
      } 
      else if (key == 6) {
        // window.location.href =
        window.open("https://xtnl.cn/#/");
        this.activeKey = "1";
      }
      else {
        this.resData = false;
      }
    },
    cardFun(name, index, id, img, parent, urltype = "", targeturl = "") {
      debugger;
      if (targeturl == "") {
        window.localStorage.setItem("anjing-name", name);
        window.localStorage.setItem("anjing-home_id", id);
        window.localStorage.setItem("anjing-img", img);
        window.localStorage.setItem("anjing-parent", parent);
        window.localStorage.setItem("anjing-urltype", urltype);

        this.$router.push({
          path: "./index",
          query: { TYPE: urltype },
        });
      } else {
        window.open(targeturl);
      }
    },
    regFun(val) {
      this.visible = val[0];
      if (val[1]) {
        this.loginOk = true;
        this.user = window.localStorage.getItem("anjing-tokenName");
        location.reload();
      }
    },
    out() {
      let data = {
        op: "logout",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_login.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$notification.open({
              message: "退出",
              duration: 2,
              description: response.data.msg,
              onClick: () => {
                console.log("登出");
              },
            });
            window.localStorage.setItem("anjing-tokenName", "");
            window.localStorage.setItem("anjing-roleName", "");
            _this.loginOk = false;
            _this.$router.push({ path: "./" });
          } else {
            _this.$notification.open({
              message: "退出",
              duration: 2,
              description: response.data.msg,
              onClick: () => {
                console.log("退出");
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style  scoped>
ul,
li {
  list-style: none;
  padding: 0;
}
ul li {
  padding-bottom: 10px;
  overflow: hidden;
}
.footer-all-list li {
  border-radius: 5px;

  padding: 4px;
  margin-bottom: 2px;
}
.footer-all-list .o {
  background: rgb(246 246 246);
}
.footer-all-list .t {
  background: rgb(246 246 246);
}
.footer-all-list .h {
  background: rgb(246 246 246);
}
/deep/ .ant-card-head-wrapper {
  text-align: left;
}
/deep/.ant-tabs-bar {
  border: none;
  margin-bottom: 15px;
  position: sticky;
  top: 0;
}
/deep/.ant-tabs-nav {
  float: right;
}
/* /deep/ .ant-tabs-nav-wrap {
  margin-bottom: 50px;
} */
/deep/ .ant-card-body {
  text-align: left;
}
/deep/.ant-card-head {
  background: #ededed;
}
/deep/ .ant-card-bordered {
  border: 1px solid #e4e4e4;
}
.card-list {
  width: 33%;
  margin-bottom: 20px;
  display: inline-block;
}
.card-list img {
  /* height: 100%; */
}
/deep/ .ant-card-cover {
  /* height:50%; */
  /* overflow: hidden; */
}
.home,
.left-pic {
  display: flex;
  justify-content: space-between;
}
.homeMin,
.left-picMin {
  display: inherit;
}
.cardWidth {
  width: 30%;
  margin: 10px;
  display: inline-block;
  height: 142px;
}
.tab-height {
  min-height: 77.7vh;
  padding: 20px 20px 0 20px;
}
.cardWidthMin {
  width: 94%;
  margin: 10px;
  display: inline-block;
}
.home-left {
  /* padding: 20px 20px 0 20px; */
  flex: 4;
  height: 100vh;
  overflow: auto;
  background: #fffff8;
}
.home-leftMin,
.home-rightMin {
  height: auto !important;
  padding-bottom: 50px !important;
}
.home-right {
  text-align: left;
  flex: 1;
  border-left: 1px solid #ccc;
  height: 100vh;
  padding: 20px 20px 0;
  font-size: 14px;
  position: relative;
}
.pic-flexo,
.pic-flext {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pic-flexoMin,
.pic-flextMin {
  width: 100%;
}
.footer-all {
  padding-bottom: 0 !important;
  margin-bottom: 3px;
}
.pic-flexo {
  padding-left: 22px;
}
.left-font {
  padding: 0 0 20px;
  font-size: 37px;
  color: #5f6368;
  text-align: left;
  margin-bottom: 0;
}
.left-fontMin {
  text-align: center;
  font-size: 20px;
}
.right-font {
  padding-left: 22px;
}
.btn {
  height: 60px;
  width: 100%;
  box-shadow: 1px 1px 2px #ccc;
  padding-top: 14px；;
}
.break {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.font-mar {
  margin: 0;
  padding-bottom: 0;
}
.card-flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.card-p {
  padding-bottom: 20px;
}
.right-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 3px;
  /* color:rgb(255, 115, 0);
  text-align: center; */
}
.footer-style {
  background: rgb(239, 239, 239);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: left;
  padding: 20px 10px;
}
.footer-title {
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-card-meta-title {
  white-space: pre-wrap;
  min-height: 47px;
}
@media (max-width: 1400px) {
  .card-list {
    width: 49%;
  }
}
@media (max-width: 800px) {
  .card-list {
    width: 100%;
  }
}
</style>
